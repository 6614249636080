/* index.css */
@import './fonts.css';


/* index.css */
body {
  font-family: 'MyCustomFont', sans-serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --toastify-color-success: black  !important;
}
